<template>
    <modal-field>
        <multi-select
            select-label="label"
            select-value="value"
            :allow-clear="false"
            :allow-empty="false"
            :multiple="false"
            :options="options"
            :placeholder="column.title"
            :open-direction="openDirection"
            v-model="extendedModel"
            @change="change"
        ></multi-select>
        <transition name="fade">
            <span v-if="error" class="w-full text-red-500 help-block">
                <span>{{ error }}</span>
            </span>
        </transition>
    </modal-field>
</template>

<script>
import MultiSelect from '~/components/MultiSelect';

export default {
    name: 'StatusesSelect',
    components: {
        MultiSelect,
    },
    props: {
        error: {
           type: String,
           required: false,
       },
       column: {
           type: Object,
           required: true,
       },
       model: {
           type: Object,
           required: true,
       },
       options: {
           type: Array,
           required: true,
       },
       val: {
           required: true,
       },
       openDirection: String,
    },
    model: {
        prop: 'val',
        event: 'update',
    },
    methods: {
        change(option) {
            this.$emit('selected', {
                updatedField : this.column.key,
                model: this.model,
                value : option.value,
            });

            this.$emit('update', option.value);
        },
    },
    computed: {
        extendedModel: {
            set(option) {
                this.model[this.column.key] = option ? option.value : '';
            },
            get() {
                if (!this.modelValue) {
                    return null;
                }

                return {
                    label: this.modelLabel || this.modelValue,
                    value: this.modelValue,
                }
            }
        },
        modelLabel() {
            for (let i = 0; i < this.options.length; ++i) {
                if (this.options[i].value === this.modelValue) {
                    return this.options[i].label;
                }
            }
            return '';
        },
        modelValue() {
            return this.model[this.column.key] || '';
        },
    },
    watch: {
        val(value) {
            this.value = value;
        }
    }
};
</script>

