<template>
    <modal-field
        class="w-1/5 pr-4 mb-3"
        :key="fieldName"
        :label="`Filter by ${label}`"
    >
        <multiselect
            v-if="fieldName === 'carrier_service_ids'"
            group-label="name"
            :group-select="false"
            group-values="items"
            :options="options"
            track-by="id"
            label="name"
            :multiple="true"
            :allow-clear="false"
            :allow-empty="true"
            v-model="filterModel"
        >
        </multiselect>

        <multiselect
            v-else-if="fieldName === 'carrier_ids'"
            :options="options"
            track-by="id"
            label="name"
            :multiple="true"
            :allow-clear="false"
            :allow-empty="true"
            v-model="filterModel"
        >
        </multiselect>

        <multi-select
            v-else
            select-label="label"
            select-value="value"
            :allow-clear="false"
            :allow-empty="true"
            :multiple="true"
            :options="options"
            v-model="filterModel"
            @change="select"
            @input="select"
            @remove="remove"
        >
        </multi-select>
    </modal-field>
</template>

<script>
import MultiSelect from '~/components/MultiSelect';

export default {
    name: 'StatusesFilter',
    components: {
        MultiSelect,
    },
    data() {
        return {
            filterModel: this.model,
        }
    },
    props: {
       model: {
           type: Array,
           required: true,
       },
       label: {
           type: String,
           required: true,
       },
       fieldName: {
           type: String,
           required: true,
       },
       options: {
           type: Array,
           required: true,
           default: (() => []),
       },
    },
    methods: {
        select(option) {
            let filter = this.cloneObject(this.filterModel);
            filter.push(this.cloneObject(option));
            this.change(filter);
        },
        remove(option) {
            let filter = this.cloneObject(this.filterModel);
            for (let i = 0; i < filter.length; ++i) {
                if (option.value === filter[i].value) {
                    filter.splice(i, 1);
                    break;
                }
            }
            this.change(filter);
        },
        change(filter) {
            this.$emit('changed', {
                updatedField : this.fieldName,
                model: filter,
            });
        },
    },
    watch: {
        filterModel(value) {
            if (this.fieldName === 'carrier_ids' || this.fieldName === 'carrier_service_ids') {
                this.$emit('changed', {
                    updatedField : this.fieldName,
                    model: value.map(item => { return item.id }),
                });
            }
        }
    }
};
</script>

