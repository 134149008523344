<template>
    <div class="page-status-dictonary">
        <transition name="fade">
            <modal-logs v-if="showModal" :items="logItems" :loading="modalLoading" @hide="showModal = false" title="Changed history" size="middle" />
        </transition>

        <card body-class="overflow-visible" class="border-radius" title="Status Dictionary" title-no-border>
            <template name="tools">
                <div slot="tools" class="relative flex items tools">
                    <button :disabled="!hasChanges" @click="save" class="btn-primary ml-4">Save</button>
                </div>
            </template>
            <div class="flex flex-col w-full">
                <div class="flex flex-wrap w-full">
                    <modal-field
                        v-for="(filter, fieldName) in filterModel" :key="fieldName"
                        v-if="filter.type === 'input'"
                        label="Filter by id"
                        class="w-1/5 pr-4 mb-3"
                    >
                        <input
                            type="number"
                            v-model="filter.value"
                            min="1"
                            step="1"
                            pattern="\d+"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')"
                        />
                    </modal-field>

                    <statuses-filter
                        v-for="(filter, fieldName) in filterModel" :key="fieldName"
                        v-if="filter.type === 'multiselect'"
                        :label="getOptionsLabelByKey(fieldName)"
                        :field-name="fieldName"
                        :model="filter.value"
                        :options="getOptionsOfRow(fieldName)"
                        @changed="updateFilterModel"
                        class="relative"
                    ></statuses-filter>

                    <template v-for="(filter, fieldName) in filterModel">
                        <modal-field
                            :key="fieldName"
                            v-if="filter.type === 'checkbox'"
                            class="mt-6"
                        >

                            <checkbox v-model="filter.value" class="mt-2">
                                <span>Unfilled only</span>
                            </checkbox>
                        </modal-field>
                    </template>
                </div>
            </div>
        </card>

        <div>
            <card body-class="p-0 pl-0 pr-0 overflow-visible" class="border-radius bg-transparent pb-6">
                <v-table
                    static
                    class="admin-table"
                    sortKey="carrier_name"
                    checkable
                    query-params
                    :sortOrder="1"
                    :text-wrappable="false"
                    :exportable="false"
                    :editable="true"
                    :ref="tableRef"
                    :key="tableRef"
                    :columns="columns"
                    :endpoint="$apiUrl.carrier.statuses"
                    :filter-params="filterParams"
                    :required-fields="['shipment_status']"
                    :page-size="0"
                    :paginate-list="[10, 15, 20, 30, 50]"
                    :paginate-value="15"
                    :errored-rows="erroredRows"
                    @check-row="rowChecked"
                >
                    <template slot="cell" slot-scope="{row, col, index}">
                        <div :class="getCellClasses(col.key)">
                            <template v-if="col.key === 'carrier_status'">
                                <div class="w-56 truncate">
                                    {{ row[col.key] }}
                                </div>
                            </template>
                            <template v-else-if="col.editable && col.editableType === 'multiselect'">
                                <span v-if="col.key === 'exception_details' && !row.is_xception">–</span>

                                <statuses-select
                                    v-model="row[col.key]"
                                    v-else-if="!formLoading"
                                    :column="col"
                                    :model="row"
                                    :options="getOptionsOfRow(col.key) || []"
                                    :error="getServerError(row.id, col.key)"
                                    :open-direction="$refs[tableRef].table.visibleRows.length < 5 ? 'bottom' : ''"
                                    @selected="updateRowModels"
                                ></statuses-select>

                                <fa v-else :icon="['fad', 'spinner-third']" class="brand-primary fa-spin" />
                            </template>
                            <template v-else-if="col.key === 'translation'">
                                <translation-input
                                    v-model="row.translation"
                                    :model="row"
                                    :column="col"
                                    :error="getServerError(row.id, col.key)"
                                    @changed="updateRowModels"
                                />
                            </template>
                            <template v-else-if="col.key === 'apply_exception_after_hours'">
                                <modal-field v-if="row.is_xception">
                                    <input v-model.number="row.apply_exception_after_hours" placeholder="Apply exception after hours" type="number" @change="updateAfterHours(row, col)"/>
                                </modal-field>
                                <span v-else>–</span>
                            </template>
                            <template v-else-if="col.key === 'visible_in_client_side'">
                                <checkbox
                                    v-model="row.visible_in_client_side"
                                    class="text-body color-text-secondary relative flex f-checkbox cursor-pointer mt-1"
                                    inverted
                                    @change="updateVisibleClientSide({ value: $event, row, col})"
                                />
                            </template>
                            <template v-else-if="col.key === 'is_xception'">
                                <button
                                    class="text-body color-text-secondary relative flex f-checkbox cursor-pointer mt-1"
                                    @click="openIsXceptionModal(row, col)"
                                >
                                    <input
                                        type="checkbox"
                                        :checked="row.is_xception"
                                        class="hidden"
                                    >
                                    <div class="f-checkbox__toggle mr-3">
                                        <svg-importer icon-name="icons/checkbox-check" :key="`checkbox_${row.id}`" />
                                    </div>
                                </button>
                            </template>
                            <template v-else-if="col.key === 'consignments'">
                                <div v-for="(consignment, index) in row[col.key]" :key="`${row.id}_${index}_${consignment.uuid}`">
                                    <a :href="`/admin/shipments/${consignment.uuid}`" target="_blank">
                                        {{ consignment.id }}
                                    </a>
                                </div>
                            </template>
                            <template v-else-if="col.key === 'actions'">
                                <div class="w-full flex flex__justify-end mt-3">
                                    <fa @click="openLogs(row)" :icon="['fal','info-circle']" />
                                </div>
                            </template>
                            <template v-else>
                                <div class="text-ellipsis">
                                    {{ row[col.key] || '-' }}
                                </div>
                            </template>
                        </div>
                    </template>

                    <div slot="bottom-tools" class="p-5 text-right">
                        <button :disabled="!hasChanges" @click="save" class="btn-primary ml-4">Save</button>
                    </div>
                </v-table>
            </card>
        </div>

        <modal :show="showIsXceptionModal" size="sm" @hide="closeIsXceptionModal">
            <h2 slot="header">Are you sure?</h2>
            <p v-if="showIsXceptionModalBody" slot="body">
                This action will cause removal of all the exceptions associated with the given status and <span class="text-red-500">can't be undone</span>
            </p>
            <div class="w-full flex flex__justify-center" slot="footer">
                <button class="btn mr-4" @click="updateIsXception">Apply</button>
                <button class="btn-transparent" @click="closeIsXceptionModal">Cancel</button>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import StatusesFilter from './StatusesFilter';
import StatusesSelect from './StatusesSelect';
import TranslationInput from './TranslationInput';
import ModalLogs from '~/components/Purchase/Processes/ModalLogs';
import { serializeParams } from '~/components/vtable/utils'
import Checkbox from '~/components/Checkbox.vue';

export default {
    name: 'StatusDictionaryIndex',
    components: {
        StatusesFilter,
        StatusesSelect,
        TranslationInput,
        ModalLogs,
        Checkbox,
    },
    data() {
        return {
            filterModel: {
                id: {
                    type: 'input',
                    value: null,
                },
                shipment_status: {
                    type: 'multiselect',
                    value: [],
                },
                carrier_ids: {
                    type: 'multiselect',
                    value: [],
                },
                carrier_service_ids: {
                    type: 'multiselect',
                    value: [],
                },
                unifaun_or_carrier_status: {
                    type: 'multiselect',
                    value: [],
                },
                exception_details: {
                    type: 'multiselect',
                    value: [],
                },
                unfilled_only: {
                    type: 'checkbox',
                    value: false,
                },
            },
            options: {},
            changedRows: [],
            serviceValidationErrors: {},
            showModal: false,
            logItems: [],
            modalLoading: false,
            formLoading: false,
            checkedRows: [],
            selectedData: null,
            showIsXceptionModal: false,
            showIsXceptionModalBody: false,
            erroredRows: [],
        }
    },
    watch: {
        filterModel: {
            handler() {
                // this.clearCheckedRows()
            },
            deep: true
        },
        'filterModel.carrier_ids': {
            async handler() {
                const result = await this.getFilterOptions();

                this.options.carrier_ids = result.carrier_ids;
                this.options.carrier_service_ids = result.carrier_service_ids;
                this.options.unifaun_or_carrier_status = result.unifaun_or_carrier_status;
            },
            deep: true,
        },
    },
    methods: {
        rowChecked(row) {
            if (row === 'checkAllRows') {
                const checked = this.$refs[this.tableRef].table.visibleRows.slice();
                this.checkedRows = checked.map(row => row.id);
                checked.forEach(checkedItem => {
                    const find = this.changedRows.find(item => item.id === checkedItem.id );

                    if (!find) {
                        this.changedRows.push({...checkedItem});
                    }
                });
                return;
            }

            if (row === 'uncheckAllRows') {
                this.checkedRows = [];
                return;
            }

            const checkedIndex = this.checkedRows.indexOf(row.id);
            
            if (checkedIndex !== -1) {
                this.checkedRows.splice(checkedIndex, 1);
            } else {
                this.checkedRows.push(row.id);
            }
        },
        parseTransportService(service) {
            const value = [
                service.first_transport_service_setting,
                service.second_transport_service_setting,
                service.third_transport_service_setting,
                service.fourth_transport_service_setting ,
            ];

            const result = value.filter(item => { return item });

            return result.join(', ');
        },
        async getFilterOptions() {
            const filterParams = {
                'filter[date_between]': `${moment().subtract(1, 'days').format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`,
            };

            const carrier_id_value = this.filterModel.carrier_ids.value;
            const carrier_service_id_value = this.filterModel.carrier_service_ids.value

            if (carrier_id_value.length) {
                filterParams[`filter[carrier_ids]`] = carrier_id_value;
            }

            if (carrier_service_id_value.length) {
                filterParams[`filter[carrier_service_ids]`] = carrier_service_id_value;
            }

            const { data } = await axios.get(`${this.$apiUrl.filterOptions}${serializeParams(filterParams)}`);

            const carrier_ids = data.data.carrier_data.all_carriers;

            let filteredCarriers = data.data.carrier_data.filtered_carriers;

            if (filteredCarriers.length) {
                filteredCarriers = filteredCarriers.map(item => {
                    return {
                        name: item.carrier.name,
                        items: item.services,
                    }
                });
            }

            return {
                carrier_ids,
                carrier_service_ids: filteredCarriers,
                unifaun_or_carrier_status: data.data.unifaun_or_carrier_status || [],
            }
        },
        async initOptions() {
            if (this.hasOptions) {
                return;
            }

            this.formLoading = true;

            const response = await axios.get(this.$apiUrl.dictionary);

            this.formLoading = false;

            const filterOptions = await this.getFilterOptions();

            this.options = {
                ...response.data.data,
                ...filterOptions,
            }

        },
        async openLogs(item) {
            this.modalLoading = true;

            this.showModal = true;

            const { data: { data } } = await axios.get(this.$apiUrl.changes + `?resource_name=status_dictionary&resource_id=${item.id}`);

            const result = data.map(item => {
                const name = item.changer_id.toLowerCase() === 'system' ? item.changer_id : item.changer_user.name;

                return {
                    date: this.parseDate(item.created_at),
                    message: `${name} changed ${item.field_name || ''} from ${this.emptyValue(item.prev_value)} to ${this.emptyValue(item.current_value)}`,
                };
            });

            this.logItems = result;

            this.modalLoading = false;
        },

        emptyValue(value) {
            if (!value || value === 'null') {
                return 'empty value';
            }

            return value;
        },

        parseDate(date) {
            if (!date) {
                return '–';
            }

            return moment(date).format('lll');
        },
        getOptionsLabelByKey(key) {
            for (let i = 0; i < this.columns.length; ++i) {
                if (this.columns[i].key === key) {
                    return this.columns[i].title;
                }
            }

            if (key === 'carrier_ids') {
                return 'Carrier';
            }

            if (key === 'carrier_service_ids') {
                return 'Carrier service'
            }

            return 'undefined';
        },
        getOptionsOfRow(optionsKey) {
            return this.options[optionsKey];
        },
        getCellClasses(colKey) {
            return 'pr-5';
        },
        updateFilterModel({updatedField, model}) {
            this.filterModel[updatedField].value = model;
        },
        updateAfterHours(row, col) {
            const value = row.apply_exception_after_hours;

            if (typeof value != 'number') {
                row.apply_exception_after_hours = 0;
            }

            if (value < 0) {
                row.apply_exception_after_hours = 0;
            }

            this.updateRowModels({
                model: row,
                updatedField: col.key,
                value: row.apply_exception_after_hours,
            });
        },
        updateVisibleClientSide({ value, row, col }) {
            this.updateRowModels({
                model: row,
                updatedField: col.key,
                value: !value,
            });
        },
        openIsXceptionModal(row, col) {
            this.selectedData = {row, col};
            this.showIsXceptionModalBody = this.selectedData.row.is_xception
            this.showIsXceptionModal = true;
        },
        closeIsXceptionModal() {
            this.showIsXceptionModal = false;
            this.selectedData = null;
        },
        async updateIsXception() {
            await this.updateRowModels({
                model: this.selectedData.row,
                updatedField: this.selectedData.col.key,
                value: !this.selectedData.row.is_xception,
            });
            this.closeIsXceptionModal();
        },
        updateTableRows(value) {
            const isValueChecked = this.checkedRows.includes(value.id);
            this.$refs[this.tableRef].table.rows.forEach(item => {
                const valueIsCurrent = item.id === value.id;

                if (!valueIsCurrent && !isValueChecked) {
                    return;
                }

                const isChecked = this.checkedRows.find(check => {
                    return item.id === check;
                });

                if (!isChecked && !valueIsCurrent) {
                    return;
                }

                item.translation = value.translation;
                item.shipment_status = value.shipment_status;
                item.exception_details = value.exception_details;
                item.apply_exception_after_hours = value.apply_exception_after_hours;
                item.visible_in_client_side = value.visible_in_client_side;
                item.is_xception = value.is_xception;

                if (!this.changedRows.find(row => row.id === item.id)) {
                    this.changedRows.push(item);
                }
            });
        },
        updateRowModels({updatedField, model, value}) {
            let newData = Object.assign({}, model);

            newData[updatedField] = value;

            let hasChangedRows = false;
            for (let i = 0; i < this.changedRows.length; ++i) {
                if (this.changedRows[i].id === model.id) {
                    this.changedRows[i] = newData;
                    hasChangedRows = true;
                    break;
                }
            }

            if (false === hasChangedRows || this.changedRows.length === 0) {
                this.changedRows.push(newData);
            }

            this.updateTableRows(newData);
        },
        save() {
            // sort changed rows ids to have the same order as currently in table
            this.changedRows = this.$refs[this.tableRef].table.rows.reduce((accum, currentRow) => {
                if (!this.changedRows.find(changedRow => changedRow.id === currentRow.id)) {
                    return accum;
                }

                accum.push(currentRow.id);
                
                return accum;
            }, []);

            if (!this.hasChanges) {
                return;
            }

            let rows = this.$refs[this.tableRef].table.rows
                .filter(row => this.changedRows.includes(row.id))
                .map(row => {
                    row.is_xception = Number(row.is_xception);
                    row.visible_in_client_side = Number(row.visible_in_client_side);

                    return row;
                });

            axios.patch(this.$apiUrl.carrier.statuses, rows).then(() => {
                this.resetChangedRows();
                this.resetValidationErrors();
                this.erroredRows = [];
                this.$snotify.success('Carrier services updated!');
            }).catch((error) => {
                this.$snotify.error('Oops! Something went wrong');

                const responseErrors = error.response.data.errors;

                const errorsProcessed = Object
                    .keys(responseErrors)
                    .reduce((accum, current) => {
                        const [erroredIndex, erroredFieldName] = current.split('.');
                        const erroredId = this.changedRows[erroredIndex];
                        
                        accum[`${erroredId}.${erroredFieldName}`] = responseErrors[current];

                        return accum;
                    }, {});
                this.serviceValidationErrors = errorsProcessed;

                const indexes = Object.keys(responseErrors).map(key => key.split('.')[0]);
                const indexSet = new Set(indexes);

                this.erroredRows = Array.from(indexSet).map(current => this.changedRows[current]);
            });
        },
        getServerError(id, fieldName) {
            return this.serviceValidationErrors[`${id}.${fieldName}`]
                ? this.serviceValidationErrors[`${id}.${fieldName}`][0]
                : '';
        },
        resetChangedRows() {
            this.changedRows = [];
        },
        resetValidationErrors() {
            this.serviceValidationErrors = {};
        },

        initFilters() {
            const query = this.$route.query;

            if (!query) {
                return;
            }

            if (query['filter-id']) {
                this.filterModel.id.value = query['filter-id'];
            }
        }
    },
    computed: {
        hasOptions() {
            return false === this.isEmptyObject(this.options);
        },
        hasChanges() {
            return this.changedRows.length > 0;
        },
        hasChecked() {
            return this.checkedRows.length > 0;
        },
        tableRef() {
            return 'status-dictionary';
        },
        filterParams() {
            let filterParams = {};

            for (let filterFieldName in this.filterModel) {
                const filter = this.filterModel[filterFieldName];

                if ((filterFieldName === 'carrier_ids' || filterFieldName === 'carrier_service_ids') && filter.value.length) {
                    filterParams[`filter[${filterFieldName}]`] = filter.value;
                } else if (filter.type === 'multiselect') {
                    if (filter.value.length > 0) {
                        filterParams[`filter[${filterFieldName}]`] = filter.value.map(option => option.value);
                    }
                } else if (filter.type === 'checkbox') {
                    if (filter.value) {
                        filterParams[`filter[${filterFieldName}]`] = filter.value;
                    }
                } else if (filter.value) {
                    filterParams[`filter[${filterFieldName}]`] = filter.value;
                }
            }

            return filterParams;
        },
        columns() {
            return [
                {
                    title: 'ID',
                    key: 'id',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: true,
                    filterable: false,
                    width: 'w-24',
                },
                {
                    title: 'Carrier detailed status',
                    key: 'carrier_status',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: false,
                    width: 'w-56'
                },
                {
                    title: 'Carrier name',
                    key: 'carrier_name',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: false,
                    width: 'w-40'
                },
                {
                    title: 'Carrier service',
                    key: 'carrier_service_name',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: false,
                    width: 'w-40'
                },
                // {
                //     title: 'Translation',
                //     key: 'translation',
                //     editable: true,
                //     editableType: 'text',
                //     toggled: true,
                //     sortable: true,
                //     truncate: false,
                //     filterable: false,
                //     width: 'w-36'
                // },
                {
                    title: 'Unifaun / Carrier Status',
                    key: 'unifaun_or_carrier_status',
                    editable: false,
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: false,
                    width: 'w-64'
                },
                {
                    title: 'vChain status',
                    key: 'shipment_status',
                    editable: true,
                    editableType: 'multiselect',
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: false,
                    width: 'w-40'
                },
                // {
                //     title: 'Exception at',
                //     key: 'exception_at',
                //     editable: true,
                //     editableType: 'multiselect',
                //     toggled: true,
                //     sortable: true,
                //     truncate: false,
                //     filterable: true,
                //     width: 'w-40'
                // },
                {
                    title: 'Is xception',
                    key: 'is_xception',
                    toggled: true,
                    sortable: false,
                    filterable: false,
                    width: 'w-40'
                },
                {
                    title: 'Exception details',
                    key: 'exception_details',
                    editable: true,
                    editableType: 'multiselect',
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: true,
                    width: 'w-48'
                },
                {
                    title: 'Apply exception after hours',
                    key: 'apply_exception_after_hours',
                    editable: true,
                    editableType: 'input',
                    toggled: true,
                    sortable: true,
                    truncate: false,
                    filterable: true,
                    width: 'w-64'
                },
                {
                    title: 'Exception not visible to client',
                    key: 'visible_in_client_side',
                    toggled: true,
                    sortable: false,
                    filterable: false,
                    width: 'w-64'
                },
                {
                    title: 'Consignments',
                    key: 'consignments',
                    toggled: true,
                    sortable: false,
                    filterable: false,
                    width: 'w-40'
                },
                {
                    title: '',
                    key: 'actions',
                    toggled: true,
                    sortable: false,
                    overflow: true,
                    width: 'w-12',
                    tdClass: 'actions',
                },
            ];
        }
    },

    async created() {
        await this.initFilters();

        await this.initOptions();
    },
};
</script>

<style lang="scss">
@import '~/styles/components/_checkbox.scss';

.page-status-dictonary {
    td.actions {
        padding-right: 1rem;
        svg {
            width: 16px;
            height: 16px;
            cursor: pointer;
        }

        .pr-5 {
            padding-right: 0 !important;
        }
    }

    td:first-child,
    th:first-child {
        // width: 24px !important;
        // padding-left: 12px;
        // min-width: 24px;
        // max-width: 24px;
        // input {
        //     display: none;
        // }
    }

    .v-table {
        .table-wrapper {
            overflow: auto;
            max-height: calc(100vh - 450px);
            min-height: 350px;
        }

        .table-bottom-wrapper {
            display: flex;
        }

        thead {
            position: sticky;
            left: 0;
            top: 0;
            z-index: 2;
        }
    }
}
</style>
