<template>
    <modal
        :adaptive="true"
        :width="700"
        :show="true"
        height="auto"
        name="logs"
        scrollable
        :size="size"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">{{ title }}</h2>
        </div>

        <div slot="body">
            <div class="pb-3">
                <div v-if="!loading" class="purchase-processes-logs-modal">
                    <div 
                        v-for="item, i in items"
                        :key="i"
                        class="flex purchase-processes-logs-modal__item"
                    >
                        <div class="f-s-10 color-grey purchase-processes-logs-modal__time mr-6 no-wrap">{{ item.date }}</div>
                        <div class="f-size-12">{{ item.message }}</div>
                    </div>

                    <div v-if="!items.length">The list is empty</div>
                </div>  

                <div v-else class="flex flex__justify-center">
                    <spinner size="21px" class="mb-4" />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Spinner from '~/components/Spinner';

export default {
    name: 'PurchaseProcessesLogs',

    components: {
        Spinner,
    },

    props: {
        title: {
            type: String,
            default: 'Detailed history log'
        },
        size: {
            type: String,
            default: 'md'
        },
        items: {
            type: Array,
            default: () => { return [] },
        },
        loading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss">
.purchase-processes-logs-modal {
    max-height: 380px;
    overflow-y: auto;
    padding-bottom: 12px;

    &__time {
        line-height: 21px;
    }

    &__item {
        margin-bottom: 12px;
    }
}
</style>
